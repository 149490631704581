import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon React library</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Getting started</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Development</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Troubleshooting</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Try React components with CodeSandbox." href="https://codesandbox.io/s/x2mjypo6pp" mdxType="ResourceCard">
      <MdxIcon name="codesandbox" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
    </Row>
    <h2 {...{
      "id": "carbon-react-library"
    }}>{`Carbon React library`}</h2>
    <p>{`The library provides front-end developers & engineers a collection of reusable React components to build websites and user interfaces. Adopting the library enables developers to use consistent markup, styles, and behavior in prototype and production work.`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <h4 {...{
      "id": "using-npm"
    }}>{`Using npm:`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm install --save carbon-components-react carbon-components carbon-icons
`}</code></pre>
    <h4 {...{
      "id": "if-you-prefer-yarn"
    }}>{`If you prefer `}<a parentName="h4" {...{
        "href": "https://yarnpkg.com/en/"
      }}>{`Yarn`}</a>{`:`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add carbon-components-react carbon-components carbon-icons
`}</code></pre>
    <p><em parentName="p">{`NB: March 29, 2019 - Please use the `}<inlineCode parentName="em">{`@next`}</inlineCode>{` tag to install Carbon v10 components. e.g. `}<inlineCode parentName="em">{`npm i carbon-components@next`}</inlineCode>{`.`}</em></p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`These components require the use of `}<a parentName="p" {...{
            "href": "https://webpack.js.org/guides/getting-started/"
          }}>{`webpack`}</a>{` in your project. See our `}<a parentName="p" {...{
            "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/blob/master/.storybook/webpack.config.js"
          }}>{`webpack.config.js`}</a>{` for an example configuration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Components do not import any of the styles themselves, use the SCSS or CSS from `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{` to bring in styling. You can also use the `}<inlineCode parentName="p">{`unpkg`}</inlineCode>{` CDN to bring in the styles wholesale - `}<inlineCode parentName="p">{`unpkg.com/carbon-components/css/carbon-components.css`}</inlineCode>{` aliases the latest CSS file.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For older browsers (e.g. IE11), polyfills listed in the `}<a parentName="p" {...{
            "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/blob/master/.storybook/polyfills.js"
          }}>{`carbon-components-react/.storybook/polyfills.js file`}</a>{` are required.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "development"
    }}>{`Development`}</h2>
    <p>{`Please refer to the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/.github/CONTRIBUTING.md"
      }}>{`Contribution Guidelines`}</a>{` before starting any work.`}</p>
    <h3 {...{
      "id": "using-the-server"
    }}>{`Using the server`}</h3>
    <p>{`We recommend the use of `}<a parentName="p" {...{
        "href": "https://github.com/storybooks/react-storybook"
      }}>{`React Storybook`}</a>{` for developing components.`}</p>
    <h4 {...{
      "id": "start-the-server"
    }}>{`Start the server:`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn storybook
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Open browser to `}<inlineCode parentName="p">{`http://localhost:9000/`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Develop components in their respective folders (`}<inlineCode parentName="p">{`/components`}</inlineCode>{` or `}<inlineCode parentName="p">{`/internal`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Write stories for your components in `}<inlineCode parentName="p">{`/.storybook`}</inlineCode>{`.`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "list-of-available-components"
    }}>{`List of available components`}</h3>
    <p>{`View available React Components `}<a parentName="p" {...{
        "href": "http://react.carbondesignsystem.com"
      }}>{`here`}</a>{`. Usage information is available when you click the blue “Show Info” button in the top right corner of the selected component.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`If you experience any issues while getting set up with Carbon Components React, please head over to the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components-react"
      }}>{`GitHub repo`}</a>{` for more guidelines and support. Please `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/issues"
      }}>{`create an issue`}</a>{` if your issue does not already exist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      